const rpc = async (url, method, params, rpcVersion = '2.0') => {
  try {
    const body = JSON.stringify({
      jsonrpc: rpcVersion,
      id: Math.floor(Math.random() * 10) + 1,
      method,
      params
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body
    });

    const data = await response.json();

    if (data.error) {
      return { error: data.error.message };
    }

    return data.result;
  } catch (err) {
    throw new Error(`Problem communicating with the Zonemaster backend. ${err}`);
  }
};

export default rpc;
