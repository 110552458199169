import validateDomain from '../utils/validateDomain';

/**
 * Checks the domain name and params for validity. Sync method.
 *
 * All checks are done localy, since the "validate_syntax" method was removed from backend.
 *
 * @param {Object}   config         - A config object, same structure as in `startDomainTest`
 *
 * @example
 * zm.validateSyntax('nic.cz')
 * // → {ok: true, message: 'Syntax ok'}
 * zm.validateSyntax({domain: 'nic.cz', ipv4: false, ipv6: true})
 * // → {ok: true, message: 'Syntax ok'}
 * zm.validateSyntax('_')
 * // → {ok: false, message: 'Invalid domain.'}
 * zm.validateSyntax('háčkyčárky.cz')
 * // → {ok: false, message: 'Domain name contains non-ascii characters and IDN conversion is not installed'}
 * // (domain is valid, but didn't pass backend validation)
 *
 * @returns {Object} data
 * @returns {Boolean} data.ok
 * @returns {String} data.message   - Human readable message from the backend
 */

export default function validateSyntax(config) {
  return new Promise((resolve) => {
    let ok = false;
    let message = null;

    if (typeof config === 'string') {
      ok = validateDomain(config);
      if (config.length === 0) {
        message = 'Missing domain.';
      } else {
        message = 'Invalid domain.';
      }
    } else if (typeof config === 'object') {
      if (config === null) {
        message = 'Missing domain.';
      } else if (config.domain) {
        ok = validateDomain(config.domain);
        if (config.domain.length === 0) {
          message = 'Missing domain.';
        } else {
          message = 'Invalid domain.';
        }
        message = 'Invalid domain.';
      } else {
        ok = false;
        message = 'Missing domain.';
      }
    }

    const res = { ok };
    if (message) {
      res.message = message;
    }
    resolve(res);
  });
}
